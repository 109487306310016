import React from 'react';
import {Link} from "react-router-dom";
import slugify from "react-slugify/dist/slugify";
import Img from 'react-image';
import Loader from "../Loader";

export default function BookItem(props) {
    const {ID, title, image_url, author_name} = props.book;
    return (
        <div key={ID} className="bookitem">
            <Link key={ID} to={"/book/details/" + ID + "/" + slugify(title)}>
                <div className="image">
                    <Img
                        alt={title}
                        src={image_url}
                        className="cover"
                        loader={Loader()}
                    />
                </div>
                <h2 className="booktitle">{title}</h2>
                <h3 className="authorname">By {author_name}</h3>
            </Link>
        </div>
    );
}