import React from "react";
import {} from 'react-router-dom'

function NoMatch(props) {
    return (
        <div>
            <h2>404</h2>
            <h3>
                No match.
            </h3>
        </div>
    );
}

export default NoMatch;