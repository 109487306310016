import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';

function Profile() {
    return (
        <>
            <div className="profile">
                <img src="/images/florian_avatar.jpg" alt="Florian" />
                <div className="profile-content">
                    <p>
                        Hallo! Mein Name ist Florian. Ich studiere Wirtschaftsinformatik an der Universität Zürich.
                        Wenn ich nicht grad mit meiner Kamera irgendwo unterwegs bin oder auf dem Laptop ein paar
                        Zeilen code, verbringe ich gerne Zeit mit Büchern. Auf dieser Website
                        kombiniere ich zwei meiner Leidenschaften zu meiner persönlichen kleinen Bibliothek.
                        Schau dich doch etwas um!<br />
                    </p>
                    <a rel="noopener noreferrer" title="Flo auf Twitter" className="icon" href="https://twitter.com/d4rkytv" target="_blank"><TwitterIcon /></a>
                </div>
            </div>
        </>
    );
}

export default Profile;