import BookItem from "./BookItem";
import React from "react";
import Loader from "../Loader";

function BooksList(props) {
    let {books} = props;
    return (
        <div className="bookslist">
            {
                books.length === 0 ?
                    <Loader/> :
                    (
                        books.map(book =>
                            <BookItem key={book.ID} book={book}/>
                        )
                    )
            }
        </div>
    )
}

export default BooksList;