import React, {useEffect, useState} from 'react';
import {TitleComponent} from "../../Utils/TitleComponent";
import {Link} from "react-router-dom";
import slugify from "react-slugify/dist/slugify";
import FetchData from "../../Utils/FetchData";
import Loader from "../Loader";
import Img from 'react-image';


function BookDetail({ match }) {
    const [book, setBook] = useState([]);
    const {title, author_id, author_name, publisher, published, num_pages,
        image_url, description, category_name, category_id} = book;
    const { id, slug } = match.params
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        FetchData("book/details/" + id)
            .then(res => {
                if (res.data.message === "success") {
                    setBook(res.data.books[0]);
                } else {
                    setError(res.data.message);
                }
                setLoad(true);
            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            });
    }, [id]);

    if (error) {
        return (<div>
            {error}
        </div>
        );
    } else if (load) {
        if (slugify(title) !== slug ) {
            setError("There's been an error loading your content");
            setLoad(false);
        }
        return (
            <React.Fragment>
                <TitleComponent title={title} />
                <div className="bookdetail">
                    <div className="detail-image">
                        <Img alt={title} src={image_url} className="cover" loader={Loader()}/>
                    </div>
                    <div className="detail-info">
                        <h1 className="componentTitle">{title}</h1>
                        <p className="belowComponentTitle">By <Link to={"/author/" + author_id + "/" + slugify(author_name)}>
                                {author_name}</Link>
                        </p>
                        <p className="metadata">
                        {publisher && (<>Publisher: {publisher}<br /></>)}
                        {published && (<>Published on: {published}<br /></>)}
                        {num_pages && (<>{num_pages} pages <br /></>)}
                        {category_id && category_name && (
                            <><Link to={"/category/" + category_id + "/" + slugify(category_name)} >{category_name}</Link><br /></>
                        )}
                        </p>
                        <p className="wysiwyg" dangerouslySetInnerHTML={{__html: description}} />
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <Loader/>
            </div>
        );
    }
}

export default BookDetail;
