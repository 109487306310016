import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../lotties/turning_pages.json'

function BooksLottie( {height, width}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return(
        <Lottie options={defaultOptions}
                height={height ? height : 150}
                width={width ? width : 150}

        />
    )
}

export default BooksLottie