import React, {useEffect, useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import BooksList from "./BooksList";
import FetchData from "../../Utils/FetchData";
import Loader from "../Loader";

function BooksHome() {
    const [books, setBooks] = useState([]);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    function fetchMoreData() {
        FetchData("books/list/" + page)
            .then(res => {
                    if (res.data.message === "success") {
                        setBooks(books.concat(Object.values(res.data.books)));
                        setPage(page + 1);
                    } else if (page > 1) {
                        setHasMore(false);
                    } else {
                        setError(res.data.message);
                    }
                }
            )
            .catch(err => {
                    setError(err.message);
                }
            );
    }

    useEffect(() => {
        fetchMoreData();
    }, []);

    if (error) {
        return (<div>{error.message}</div>)
    } else {
        return (
            <InfiniteScroll
                dataLength={page}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loader/>}
                className="infinite"
            >
                <h1 className="componentTitle">Books Read</h1>
                <BooksList books={books}/>
            </InfiniteScroll>
        )
    }
}

export default BooksHome;