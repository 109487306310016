import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import BooksHome from "./Books/BooksHome";
import NoMatch from "./NoMatch";
import BookDetail from "./Books/BookDetail";
import {TitleComponent} from "../Utils/TitleComponent";
import GetBooks from "./Books/GetBooks";
import SideBar from "./SideBar";
import Profile from "./Profile";

function App() {
    return (
        <Router>
            <div className="app">
                <Route component={SideBar} />
                <div className="wrapper">
                    <div className="content">
                          <Switch>
                              <Route exact path="/challenge/:id">
                                  <GetBooks apiPath="books/list/challenge/" />
                              </Route>
                              <Route exact path="/category/:id/:name?">
                                  <GetBooks apiPath="books/list/category/" />
                              </Route>
                              <Route exact path="/author/:id/:name?">
                                  <GetBooks apiPath="books/list/author/" />
                              </Route>
                              <Route exact path="/book/details/:id/:slug" component={BookDetail} />
                              <Route exact path="/">
                                  <TitleComponent />
                                  <div className="cntbottom">
                                    <Profile/>
                                  </div>
                                  <div className="cntbottom">
                                      <h1 className="componentTitle">Now Reading</h1>
                                      <GetBooks apiPath="books/list/reading" />
                                  </div>
                                  <BooksHome/>
                              </Route>
                              <Route path="*">
                                  <NoMatch />
                              </Route>
                          </Switch>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;