import React, {useEffect, useState} from 'react';
import {TitleComponent} from "../../Utils/TitleComponent";
import BooksList from "./BooksList";
import FetchData from "../../Utils/FetchData";
import {useParams} from 'react-router-dom'
import Loader from "../Loader";

function GetBooks({apiPath}) {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    let { id } = useParams();

    useEffect(() => {
        const api = id ? apiPath + id : apiPath;
        FetchData(api)
            .then(res => {
                    if (res.data.message === "success") {
                        setData(res.data);
                        setLoad(true);
                    } else {
                        setError(res.data.message);
                    }
                }
            )
            .catch(err => {
                    setError(err.message);
                }
            );
    }, [id, apiPath]);

    if (error) {
        return (<div>{error}</div>);
    } else if (load) {
        const {title, subtitle} = data;
        const books = Object.values(data.books);
        return (
            <React.Fragment>
                <h1 className="componentTitle">{title}</h1>
                <TitleComponent title={title}/>
                <h2 className="componentSubTitle">{subtitle}</h2>
                <BooksList books={books} />
            </React.Fragment>
        )
    } else {
        return (<Loader/>)
    }
}

export default GetBooks;