import React from 'react';
import {Link} from "react-router-dom";
import BooksLottie from "./BooksLottie";
import HomeIcon from "@material-ui/icons/Home";
import BookIcon from "@material-ui/icons/MenuBook";

function SideBar({ location }) {
    const thisYear = new Date().getFullYear();
    const pathname = location.pathname;
    return (
        <div className="sidebar">
            <div className="logo">
                <Link to={"/"}>
                    <BooksLottie width={200} height={200} />
                </Link>
            </div>
            <div className="browse">
                <h3 className="hideOnMobile">Browse</h3>
                <nav>
                    <ul>
                        <li className={pathname === "/" && "active"}>
                            <Link to={"/"}>
                                <HomeIcon /> <span className="hideOnMobile">Library</span>
                            </Link>
                        </li>
                        <li className={pathname === ("/challenge/" + thisYear) && "active"}>
                            <Link to={"/challenge/" + thisYear}>
                                <BookIcon /> <span className="hideOnMobile">Reading Challenge {thisYear} </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="hideOnMobile">
                    <h3>Genres</h3>
                    <ul className="cats">
                        <li><Link to="/category/2/biography-autobiography">Biography & Autobiography</Link></li>
                        <li><Link to="/category/1/fiction">Fiction</Link></li>
                        <li><Link to="/category/3/comics-graphic-novels">Comics & Graphic Novels</Link></li>
                    </ul>
                    <h3>Devs</h3>
                    <p className="devs">
                        App created using Goodreads and Google Books API. It uses Lumen (Laravel) and ReactJS.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SideBar;